<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('form.name') }}</label>
            <vs-input
              v-model="typeform.name"
              placeholder="James J. Jameson"
              type="text"
              class="shadow-lg"
              border
              :class="{
                'is-invalid': typesubmit && $v.typeform.name.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>UUID</label>
            <vs-input
              v-model="typeform.uuid"
              placeholder="1-2-3"
              type="text"
              class="shadow-lg"
              border
            />
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('crupiers.form.username') }}</label>
            <vs-input
              v-model="typeform.userName"
              :placeholder="$t('crupiers.form.username')"
              type="text"
              class="shadow-lg"
              border
              :class="{
                'is-invalid': typesubmit && $v.typeform.userName.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.userName.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.userName.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('initSession.email') }}</label>
            <vs-input
              v-model="typeform.email"
              :placeholder="$t('initSession.email')"
              type="text"
              class="shadow-lg"
              border
              :class="{
                'is-invalid': typesubmit && $v.typeform.email.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.email.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.email.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <label class="d-inline-flex align-items-center w-100">
            {{ $t('filter.client') }}:
          </label>
          <select
            v-model="typeform.client"
            name="client"
            class="form-select form-select-sm m-2 border-0 shadow-lg"
            placeholder="Seleccione.."
          >
            <option disabled selected>{{ $t('helpers.select') }}...</option>
            <option
              v-for="(item, index) in clients"
              :key="index"
              :value="item._id"
            >
              {{ item.name }}
            </option>
          </select>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t('crupiers.form.password') }}</label>
            <vs-input
              v-model="typeform.password"
              :placeholder="$t('crupiers.form.password')"
              type="password"
              class="shadow-lg"
              border
              :class="{
                'is-invalid': typesubmit && $v.typeform.password.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.password.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.password.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mb-0">
        <div class="d-flex">
          <vs-button type="submit">{{ $t('form.save') }}</vs-button>
          <vs-button @click="$emit('closeModa')" type="button" success>
            {{ $t('form.abort') }}
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      clients: [],
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      userName: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'crupiers/getSuccess',
      envs: 'notification/mapEnvs',
      getErrors: 'notification/getErrors',
    }),
  },
  methods: {
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: 'auto',
      });
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`;
          this.openWinNotification(null, 'warning', 'ERROR', message);
        });
      }
    },
    async save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) return console.log('error', this.$v);

      if (this.typeform._id) {
        await this.update(this.typeform);

        if (!this.success) {
          return Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'ERROR ACTUALIZANDO CRUPIER',
            showConfirmButton: false,
            timer: 1500,
          });
        }

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Crupier guardado con exito',
          showConfirmButton: false,
          timer: 1500,
        });

        this.$emit('closeModa');
        this.checkErrors();
      } else {
        await this.store(this.typeform);

        if (!this.success) {
          return Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'ERROR CREANDO CRUPIER',
            showConfirmButton: false,
            timer: 1500,
          });
        }

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Crupier registrada con exito',
          showConfirmButton: false,
          timer: 1500,
        });

        this.checkErrors();
        this.$emit('closeModa');
      }
    },
    getClients() {
      this.$http
        .get('/clients')
        .then((response) => {
          this.clients = response.data.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapActions({
      store: 'crupiers/store',
      update: 'crupiers/update',
    }),
  },
  mounted() {
    this.getClients();
  },
};
</script>
